<template>
  <div class="page">
    <div class="c-page o-page-wrapper c-page_partner">
      <Sidebar :status="'announcements-detail'"></Sidebar>
      <div class="o-sidebar-content o-sidebar-content_detail">
        <div class="c-page__content c-page__content_announcementdetail">
          <ContentDetail></ContentDetail>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import ContentDetail from '@/components/library/AnnouncementDetailPage.vue'

export default {
  name: 'NewsMediaRoom',
  components: {
    Sidebar,
    Footer,
    ContentDetail
  },
  data() {
    return {
      bannerData: [
        {
          img: require('@/assets/images/banner/Diverse range of innovative products.jpg'),
          title: "Diverse range of innovative <br />products <span class='c-page-banner__regular'>for institutions and</span>",
          subtitle: "accredited clients",
          buttonText: "Find out more >",
          buttonPage: "/institutional/wealth"
        },
      ],
      theme: 'two',
    }
  },
  mounted: function () {
    this.updateTitleMeta('Sparrow | Read Latest Media Updates and News on Sparrow')
    this.updateDescriptionMeta('Sparrow shares current news, press releases, and media coverages on products and solutions offered, innovations, technology and thought leadership on digital assets.')
    this.updateKeywordMeta('sparrow, sparrow exchange, wealth management, trading platform, cryptocurrency trading')
  }
}
</script>
